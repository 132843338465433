import React, { useState, useRef, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { useDispatch } from "react-redux"
import { setIsLeavingPage } from "state/actions"

const isSamePath = to =>
  typeof window !== "undefined" &&
  window.location.pathname.replace(/\/$/, "") === to.replace(/\/$/, "")

const TransitionLink = ({ to, ...restProps }) => {
  const dispatch = useDispatch()
  const isMounted = useRef()
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  return (
    <Link
      to={to}
      onClick={e => {
        e.preventDefault()
        if (clicked || isSamePath(to)) {
          return
        }
        setClicked(true)
        dispatch(setIsLeavingPage(true))
        setTimeout(() => {
          navigate(to)
          if (isMounted.current) setClicked(false)
        }, 150)
      }}
      {...restProps}
    />
  )
}

export default TransitionLink
