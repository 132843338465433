import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useSpring, animated } from "react-spring"
import { setIsLeavingPage } from "state/actions"

const PageAnimator = ({ className, children }) => {
  const dispatch = useDispatch()
  const isLeavingPage = useSelector(state => state.isLeavingPage)

  const [props, set] = useSpring(() => ({
    config: { mass: 1, tension: 350, friction: 26 },
    opacity: 1,
    transform: "translate3d(0px, 0px, 0px)",
    from: { opacity: 0, transform: "translate3d(0px, 20px, 0px)" },
  }))

  useEffect(() => {
    if (isLeavingPage) {
      set({ opacity: 0, transform: "translate3d(0px, -20px, 0px)" })
      return
    }
    set({ opacity: 1, transform: "translate3d(0px, 0px, 0px)" })
  }, [isLeavingPage, set])

  useEffect(() => {
    dispatch(setIsLeavingPage(false))
  }, [dispatch])

  return (
    <animated.div style={props} className={className}>
      {children}
    </animated.div>
  )
}

export default PageAnimator
